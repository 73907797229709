import React, {useEffect, useState} from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {tryGetConsumers} from '../../middleware/consumerData.js'
export default function UserTable() {
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'anon_id', headerName: 'Anon_ID', flex:1},
        { field: 'email', headerName: 'Email', flex:1},
        { field: 'phone', headerName: 'Phone',flex:1 },
        { field: 'utm_campaign', headerName: 'UTM Campaign', flex:1 },
        { field: 'utm_medium', headerName: 'UTM Medium', flex:1},
        { field: 'utm_term', headerName: 'UTM Term', flex:1 },
        { field: 'utm_content', headerName: 'UTM Content', flex:1},
        { field: 'utm_source', headerName: 'UTM Source', flex:1 }
    ];
    const paginationModel = { page: 0, pageSize: 5 };
    useEffect(() => {
        init();
    },[])

    const init = async () => {
        const data = await tryGetConsumers();
        setRows(data.data)
    }
    if (rows.length === 0) {
        return <h1>No data</h1>
    }
    return (
        <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
            />
        </Paper>)
}