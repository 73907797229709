import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

/* Pages */
import Entry from './pages/entry';

export default function AllRoutes() {
    return(
        <Routes>
            <Route exact path={'/'} element={<Entry />} />
            <Route path={'/dashboard'} element={<Entry />} />
            <Route path={'/events'} element={<Entry/>}/>
            <Route path={'/users'} element={<Entry/>}/>
            <Route path={'/report/:id'} element={<Entry />} />
            <Route path={'/users/:id'} element={<Entry />} />
            <Route path={'/*'} element={<Navigate to={'/'} replace />} />
        </Routes>
    )
}