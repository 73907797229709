import React, {useEffect, useState} from "react";
import Select from 'react-dropdown-select'
import {
    tryGetDataAdCampaign, tryGetDataAdCampaignOptions, tryGetDataAdCreative, tryGetDataAdCreativeOptions,
    tryGetDataAdSource, tryGetDataAdSourceOptions, tryGetTotalData
} from "../../middleware/getAdData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Total from "./total";
import styled from "styled-components";
import {Box, Button, Divider, Drawer, Link, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {colors} from '../../components/colors'
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime} from "luxon";
import TableCell from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";

export const TableContainerStyled = styled(TableContainer)`
    .cellName {
      background-color: ${colors.action70};
      font-weight: bold;
    }

  .dateRow {
    background-color: ${colors.action70};
  }

  .data {
    background-color: ${colors.canvaSand};
  }

  .rowName {
    background-color: ${colors.canvaSand};
  }

  .activeDate {
    background-color: ${colors.secondary100};
  }
  
`
export const StyledTable = styled(Table)`
  margin-bottom: 20px;
  overflow-x: scroll;
`
export const StyledTableCell = styled(TableCell)`
  width: 4rem
`
export default function Reports() {
    const [optionsAdSource, setOptionsAdSource] = useState([
        {
            value :'Google',
            label:'Google'
        }
    ])
    const [optionsAdCampaign, setOptionsAdCampaign] = useState([])
    const [optionsAdCreative, setOptionsAdCreative] = useState([])
    const [ad, setAdSource] = useState("")
    const [adCampaign, setAdCampaign] = useState('')
    const [adCreative, setAdCreative] = useState('')
    const [currentTotal, setCurrentTotal] = useState({})
    const [currentData, setCurrentData] = useState({})
    const [currentCreative, setCurrentCreative] = useState({})
    const drawerWidth = 240;
    const [dateRange, setDateRange] = useState({
        start : DateTime.now(),
        end : DateTime.now()
    })
    const [locale, setLocale] = React.useState('en-us');

    const [dateType, setDateType] = useState('Daily')
    const [utmOptions, setUtmOptions] = useState({
        utm_content: [
            {
                value: 'some content',
                label: 'some label'
            }
        ],
        utm_term: [
            {
                value: 'some content',
                label: 'some label'
            }
        ],
        utm_source: [
            {
                value: 'some content',
                label: 'some label'
            }
        ],
        utm_medium: [
            {
                value: 'some content',
                label: 'some label'
            }
        ],
        utm_campaign: [
            {
                value: 'some content',
                label: 'some label'
            }
        ]
    })
    const [utms, setUtms] = useState({
        utm_content: [],
        utm_term: [],
        utm_source: [],
        utm_medium: [],
        utm_campaign: []
    })
    const [isLoading, setLoading] = useState(true)

    // Total data
    // Ad source options
    useEffect( () => {
        const init = async () => {
            await sortByDateType(dateType)
            setLoading(false)
            const adSourceOptions = await tryGetDataAdSourceOptions()
            const o = []
            for (let d of adSourceOptions.data) {
                o.push({
                    value:d,
                    label:d
                })
            }
            setOptionsAdSource(o)
        }
        init()
    },[])

    // Ad source updates
    // Ad campaign options
    useEffect(() => {
        const update = async () => {
            // When ad is updated, calls the api to grab the data for the given ad source
            // Sorts the data by current set date type
            await sortByDateType(dateType)
            // Grabs the Ad source campaign options
            if (ad.length > 0) {
                const adCampaignOptions = await tryGetDataAdCampaignOptions(ad)
                const o = []
                for (let d of adCampaignOptions.data) {
                    o.push({
                        value: d,
                        label: d
                    })
                }
                setOptionsAdCampaign(o)
            }
        }
        update()
    },[ad])

    // // Ad campaign updates
    // // Ad creative options
    useEffect(() => {
        const update = async () => {
            // When campaign is updated, calls the api to grab the data for the given ad campaign
            // Sorts the data by current set date type
            await sortByDateType(dateType)
            if (adCampaign.length > 0) {
                const adCreativeOptions = await tryGetDataAdCreativeOptions(adCampaign)
                const o = []
                // Grabs the Ad source creative options

                for (let d of adCreativeOptions.data) {
                    o.push({
                        value: d,
                        label: d
                    })
                }
                setOptionsAdCreative(o)
            }
        }
        update()
    }, [adCampaign])

    // // Ad creative updates
    useEffect(() => {
        const update = async () => {
            // When creative is updated, calls the api to grab the data for the given ad creative
            // Sorts the data by current set date type
            await sortByDateType(dateType)
        }
        update()
    }, [adCreative])

    useEffect(() => {
        const update = async () => {
            if (adCreative !== '') {
                setCurrentCreative(currentData)
                setUtmOptions({
                    ...utmOptions,
                    utm_medium: currentData.map(m => ({"value": m.utm_medium, "label": m.utm_medium})),
                    utm_campaign: currentData.map(m => ({"value": m.utm_campaign, "label": m.utm_campaign})),
                    utm_term: currentData.map(m => ({"value": m.utm_term, "label": m.utm_term})),
                    utm_content: currentData.map(m => ({"value": m.utm_content, "label": m.utm_content})),
                    utm_source: currentData.map(m => ({"value": m.utm_source, "label": m.utm_source}))
                })
            }

        }
        update()
    }, [currentData])


    // UTM parameters
    useEffect(() => {
        if (adCreative !== ""
            && utms.utm_medium.length > 0
            || utms.utm_content.length > 0
            || utms.utm_source.length > 0
            || utms.utm_term.length > 0
            || utms.utm_campaign.length > 0) {
            setCurrentCreative(filterUTM())
        }
    },[utmOptions])

    useEffect(() => {
       const update = async() => await sortByDateType(dateType)
        update()
    },[dateType])

    // Sorts data by either daily, weekly, or last 6 months
    const sortByDateType = async (type) => {
        const currentDate = DateTime.now().setZone('America/New_York').toISODate()
        let data;
        let total;
        console.log(type)
        if (type === 'Daily') {
            const sevenDays = DateTime.now().setZone('America/New_York').minus({weeks:1}).toISODate()
            console.log(sevenDays)
            console.log(currentDate)
            if (ad !== "" && adCampaign === "" && adCreative === "") {
                data = await tryGetDataAdSource(ad, sevenDays, currentDate, 'Daily')
                setCurrentData(data.data)
            }
            else if (adCampaign !== "" && adCreative === "") {
                data = await tryGetDataAdCampaign(adCampaign, sevenDays, currentDate, 'Daily')
                setCurrentData(data.data)
            }
            else if (adCreative !== "") {
                data = await tryGetDataAdCreative(adCreative, sevenDays, currentDate, 'Daily')
                setCurrentData(data.data)

            }
            total = await tryGetTotalData(sevenDays, currentDate, 'Daily')
            setCurrentTotal(total.data)

        } else if (type === 'Weekly') {
            const sixWeeks = DateTime.now().setZone('America/New_York').minus({weeks:7}).toISODate()
            if (ad !== "" && adCampaign === "" && adCreative === "") {
                data = await tryGetDataAdSource(ad, sixWeeks, currentDate, 'Weekly')
                setCurrentData(data.data)
            }
            else if (adCampaign !== "" && adCreative === "") {
                data = await tryGetDataAdCampaign(adCampaign, sixWeeks, currentDate, 'Weekly')
                setCurrentData(data.data)
            }
            else if (adCreative !== "") {
                data = await tryGetDataAdCreative(adCreative, sixWeeks, currentDate, 'Weekly')
                setCurrentData(data.data)
            }
            total = await tryGetTotalData(sixWeeks, currentDate, 'Weekly')
            setCurrentTotal(total.data)

        } else if (type === 'Monthly') {
            const sixMonths = DateTime.now().setZone('America/New_York').minus({months:6}).toISODate()
            if (ad !== "" && adCampaign === "" && adCreative === "") {
                data = await tryGetDataAdSource(ad, sixMonths, currentDate, 'Monthly')
                setCurrentData(data.data)
            }
            else if (adCampaign !== "" && adCreative === "") {
                data = await tryGetDataAdCampaign(adCampaign, sixMonths, currentDate, 'Monthly')
                setCurrentData(data.data)
            }
            else if (adCreative !== "") {
                data = await tryGetDataAdCreative(adCreative, sixMonths, currentDate, 'Monthly')
                setCurrentData(data.data)

            }
            total = await tryGetTotalData(sixMonths, currentDate, 'Monthly')
            setCurrentTotal(total.data)
        }



    }



    // Sorts data by a given date range
    const sortByDateRange = async (dateRange) => {
        if (dateRange.start !== '' && dateRange.end !== '') {
            const startDate = dateRange.start
            const lastDate = dateRange.end
            let dateType = 'Daily'
            let range = startDate - lastDate;
            let data = {};
            let total;
            if (range <= 7) {
                dateType = 'Daily'
            } else if (range <= 42 && range >= 7) {
                dateType = 'Weekly'
            } else if (range > 42) {
                dateType = 'Monthly'
            }
            if (ad !== "" && adCampaign === "" && adCreative === "") {
                data = await tryGetDataAdSource(ad, lastDate, startDate, dateType)
                setCurrentData(data.data)
            }
            else if (adCampaign !== "" && adCreative === "") {
                data = await tryGetDataAdCampaign(adCampaign, lastDate, startDate, dateType)
                setCurrentData(data.data)
            }
            else if (adCreative !== "") {
                data = await tryGetDataAdCreative(adCreative, lastDate, startDate, dateType)
                setCurrentData(data.data)
            }
            total = await tryGetTotalData(lastDate, startDate, dateType)
            setCurrentTotal(total.data)
        }
    }



    // Filter data by utms chosen in the selects
    const filterUTM = () => {
        // Filter the data based on the specified parameters
        return currentData.filter((d) =>
            utms.utm_medium.includes(d.utm_medium) ||
            utms.utm_campaign.includes(d.utm_campaign) ||
            utms.utm_source.includes(d.utm_source) ||
            utms.utm_term.includes(d.utm_term) ||
            utms.utm_content.includes(d.utm_content)
        )
    }


    if (isLoading) {

    } else {
        return (
        <>
            <Box sx={{display: 'flex', width: '100%'}}>
                <CssBaseline/>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', zIndex:1,backgroundColor:"transparent"
                        },
                    }}
                >
                    <Toolbar/>
                    <Box sx={{overflow: 'auto'}}>
                        <List sx={{margin: '0 20px 0 20px'}}>
                            {optionsAdSource.length > 0 &&
                                <>
                                    <h4>Ad Source</h4>
                                    <Select
                                        clearable
                                        options={optionsAdSource}
                                        values={[]}
                                        onChange={(value) => {setAdSource(value.length <= 0 ? '': value[0].label)}}/>
                                </>}                            {optionsAdCampaign.length > 0 &&
                            <>
                                <h4>Ad Campaign</h4>
                                <Select
                                    values={[]}
                                    clearable
                                options={optionsAdCampaign}
                                onChange={(value) => setAdCampaign(value.length <= 0 ? '': value[0].label)}  />
                            </>
                            }
                            {optionsAdCreative.length > 0 &&
                                <>
                            <h4>Ad Creative</h4>
                            <Select
                                values={[]}
                                clearable
                                options={optionsAdCreative}
                                onChange={(value) => setAdCreative(value.length <= 0 ? '': value[0].label)}  />
                            </>}
                            {currentCreative.length > 0 &&
                                <>
                            <h4>UTM Medium</h4>
                            <Select
                                options={utmOptions.utm_medium}
                                values={[]}
                                multi
                                clearable
                                onChange={(value) => setUtms({
                                    ...utms,
                                    utm_medium: value.length <= 0 ? '' : value[0].label
                                })}                                    />
                            <h4>UTM Content</h4>
                            <Select
                                options={utmOptions.utm_content}
                                values={[]}
                                multi
                                clearable
                                onChange={(value) => setUtms({
                                    ...utms,
                                    utm_content: value.length <= 0 ? '' : value[0].label
                                })}
                            />
                            <h4>UTM Term</h4>
                            <Select
                                options={utmOptions.utm_term}
                                multi
                                clearable
                                onChange={(value) => setUtms({
                                    ...utms,
                                    utm_term: value.length <= 0 ? '' : value[0].label
                                })}
                            />
                            <h4>UTM Source</h4>
                            <Select
                                options={utmOptions.utm_source}
                                values={[]}
                                multi
                                onChange={(value) => setUtms({
                                    ...utms,
                                    utm_source: value.length <= 0 ? '' : value[0].label
                                })}
                            />
                                </>
                            }
                        </List>
                        <Divider/>
                        <List>
                            {['Daily', 'Weekly', 'Monthly'].map((text, index) => (
                                <ListItem key={text} disablePadding sx = {{backgroundColor : text === dateType && colors.primary70}}>
                                    <ListItemButton onClick={() => setDateType(text)}
                                                >
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
                                <DatePicker
                                    label="Start"
                                    value={dateRange.start}
                                    onChange={(value) => setDateRange({...dateRange, start: value})}
                                />
                                <DatePicker
                                    label="End"
                                    value={dateRange.end}
                                    onChange={(value) => setDateRange({...dateRange, end: value})}
                                />
                                <Button onClick={() => sortByDateRange(dateRange)}>Filter by date range</Button>

                            </LocalizationProvider>
                        </List>
                    </Box>
                </Drawer>

                <AppBar position="absolute" sx={{
                    left: drawerWidth,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: colors.primary30,
                    top:'68px',
                    height:'68px',
                    boxShadow:0
                }}>
                    <Toolbar sx={{display: 'flex'}}>
                        <Button
                            key={'create'}
                            component={Link}
                            href={`/create`}
                            sx={{ my: 1, color: 'white', display: 'block' }}
                        >
                            <MenuItem> Create Report</MenuItem>
                        </Button>
                        <Button
                            key={'view'}
                            component={Link}
                            href={`/view`}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <MenuItem>View Reports</MenuItem>
                        </Button>
                        {/*<Typography variant="h6" noWrap component="div">*/}
                        {/*    {ad.length > 0 && ` ${ad}`}*/}
                        {/*    {adCampaign.length > 0 && ` > ${adCampaign}`}*/}
                        {/*    {adCreative.length > 0 && ` > ${adCreative}`}*/}
                        {/*</Typography>*/}
                    </Toolbar>
                </AppBar>
                <Box sx={{width: '100%'}}>
                    <Toolbar/>
                   <Total rows={currentTotal} key={"total"} name={"Total"}/>
                    {/* "Each Ad Source Data" Tables*/}
                    <TableContainerStyled>
                        {ad === "" && adCreative === "" && <h3> Please choose a data input </h3>}
                        {currentCreative.length > 0 && <Total rows={currentCreative} key={"creative"} name={adCreative}/>}
                        {ad !== "" && adCreative === "" && Object.keys(currentData).map((a, index) => {
                            return <StyledTable sx={{minWidth: '100%'}} aria-label="simple table" key={a} className={'tableStyle'}>
                                <TableHead>
                                    <TableRow className={'dateRow'}>
                                        <TableRow>
                                            <StyledTableCell className={'cellName'}>{a}</StyledTableCell>
                                        </TableRow>
                                        {currentData[a].map((row, index) => (

                                            <StyledTableCell component="th" scope="row" key={"date" + index}>
                                                {`${new Date(row.created).getMonth() + 1}/${new Date(row.created).getDate()}/${new Date(row.created).getFullYear()}`}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                                            Spend
                                        </StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"spend" + index}>
                                                ${Number(row.spend).toLocaleString('en')}
                                            </StyledTableCell>
                                        })}

                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"spend" + index}>
                                                {Number(row.impressions).toLocaleString()}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Clicks</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"spend" + index}>
                                                {Number(row.clicks).toLocaleString()}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Spend / Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerImpressions = (row.spend / row.impressions).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                ${spendPerImpressions}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                                            Spend / Clicks
                                        </StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerClick = (row.spend / row.clicks).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                ${spendPerClick}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} ># Clicks / #
                                            Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                {clicksToImpressions}%
                                            </StyledTableCell>
                                        })}
                                    </TableRow>

                                </TableBody>
                            </StyledTable>
                        })}
                    </TableContainerStyled>
                </Box>
            </Box>
        </>


    )
    }
}