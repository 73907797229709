import {axiosReq} from "../utils/axios";


export const tryGetConsumers = () => {
    return axiosReq(`api/consumers`, "GET", {}, {data : {}});

}

export const tryGetEvents = () => {
    return axiosReq(`api/events`, "GET", {}, {data: {}});

}
