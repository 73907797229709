import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        variable: null
    },
    reducers: {
        actionName: (state, action) => {
            state.variable = action.payload.variable
        }
    }
});

export const {actionName} = sessionSlice.actions;

export default sessionSlice.reducer