import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

/* Pages */
import Entry from './pages/entry';

export default function AllRoutes() {
    return(
        <Routes>
            <Route exact path={'/'} element={<Entry />} />
            <Route path={'/*'} element={<Navigate to={'/'} replace />} />
        </Routes>
    )
}