import React from 'react';
import AdvisorTable from "./table/advisor";
import EventsTable from "./table/events";
import Nav from "./nav";
import UserTable from "./table/users";
import Dashboard from "./table/dashboard";

export default function Entry() {
    return (
        <>
            <Nav />
            {window.location.href.includes("dashboard") &&  <Dashboard/>}
            {window.location.href.includes("users") && <UserTable />}
            {window.location.href.includes("advisor") && <AdvisorTable />}
            {window.location.href.includes("events") && <EventsTable />}

        </>
    )

};