import React from "react";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {StyledTable, StyledTableCell, TableContainerStyled} from "./reports";

export default function Total(props) {
    const rows = props.rows
    const name = props.name
    if (rows.length <= 0) {
        return <h3>No data for the given timeframe or data input</h3>
    }
    return <TableContainerStyled>
        {/*  "Total Paid Data" Table       */}
        <StyledTable sx={{minWidth:600}} aria-label="simple table" className={'totalTable'}>
            <TableHead>
                <TableRow className={'dateRow'}>
                    <StyledTableCell className={"cellName"}>{name}</StyledTableCell>
                    {rows.map((row, index) => (

                        <StyledTableCell component="th" scope="row" key={"date" + index}>
                            {`${new Date(row.created).getMonth() + 1}/${new Date(row.created).getDate()}/${new Date(row.created).getFullYear()}`}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            ${Number(row.spend).toLocaleString()}
                        </StyledTableCell>
                    })}

                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            {Number(row.impressions).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Clicks</StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            {Number(row.clicks).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Spend / Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        const impressionsPerClick = (row.spend / row.impressions).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${impressionsPerClick}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend / Clicks
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        const spendPerClick = (row.spend / row.clicks).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerClick}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}># Clicks / # Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>
            </TableBody>
        </StyledTable>



    </TableContainerStyled>
}