import Reports from "./reports";
import React from 'react';
import AppBar from "@mui/material/AppBar";
import {colors} from "../../components/colors";
import Toolbar from "@mui/material/Toolbar";
import {Button, Link} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";


export default function Dashboard() {
    return (
        <>
        <Reports />
        </>
    )
}